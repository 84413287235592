@use "../utils" as *;

/*----------------------------------------*/
/*  15. MEANMENU CSS START
/*----------------------------------------*/

/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #070337;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #070337;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 10px 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #e0e3ed;
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 10px 10%;
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 10px 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 10px 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 10px 25%;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
  font-size: 14px;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

/*--- preloader ---*/

.dark #preloader {
  background-color: #232323;
}

#preloader {
  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  bottom: 0;

  background-color: #f7f7f7;

  z-index: 999999;
}

.preloader {
  width: 50px;

  height: 50px;

  display: inline-block;

  padding: 0px;

  text-align: left;

  box-sizing: border-box;

  position: absolute;

  top: 50%;

  left: 50%;

  margin-left: -25px;

  margin-top: -25px;
}

.preloader span {
  position: absolute;

  display: inline-block;

  width: 50px;

  height: 50px;

  border-radius: 100%;

  background: #fb4f53;

  -webkit-animation: preloader 1.3s linear infinite;

  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.8s;

  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0.7, 0.7);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.7, 0.7);
  }
}

@-webkit-keyframes preloader {
  0% {
    transform: scale(0.7, 0.7);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.7, 0.7);
  }
}

/*--- end of preloader ---*/

/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}
.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}
.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}
.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.mean-container a.meanmenu-reveal {
  display: none !important;
}
.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  border-top: 1px solid #3c3962;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  &:hover {
    color: #10d0a1;
  }
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 8px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid #3c3962 !important;
  height: 26px;
  width: 26px;
  line-height: 26px;
  color: #fff;
  top: 0;
  font-weight: 400;
  &:hover {
    background: var(--clr-theme-1);
    color: var(--tp-common-white);
    border-color: var(--clr-theme-1);
  }
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}
.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
  font-size: 14px;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: #10d0a1;
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  color: var(--tp-the);
  cursor: pointer;
}
