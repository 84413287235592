@use "../utils" as *;

/*----------------------------------------*/
/*  15. BLOG CSS START
/*----------------------------------------*/
.tp-blog {
  @include transition(0.3s);
  &:hover {
    box-shadow: 0px 21px 15px rgba(190, 195, 204, 0.13);

    & .tp-blog__thumb {
      & img {
        transform: scale(1.15);
      }
    }
    & .tp-blog__title {
      & a {
        background-size: 0% 1px, 100% 1px;
      }
    }
    & .tp-blog__date {
      transform: rotateY(360deg);
      & h4 {
        box-shadow: inset 0 0 0 60px var(--tp-icon-blue);
        color: var(--tp-common-white);
        & span {
          color: var(--tp-common-white);
        }
      }
    }
  }
  &__thumb {
    border-radius: 5px 5px 0 0;
    & img {
      width: 100%;
      @include transition(1s);
    }
  }
  &__date {
    position: absolute;
    top: 15px;
    right: 15px;
    @include transition(0.4s);
    & h4 {
      height: 45px;
      width: 45px;
      background-color: var(--tp-common-white);
      color: var(--tp-icon-blue);
      margin: 0;
      font-size: 16px;
      padding: 5px 2px;
      border-radius: 5px;
      @include transition(1s);
      & span {
        font-size: 12px;
        font-weight: 400;
        display: block;
        font-family: "Archivo";
        color: var(--tp-text-body);
      }
    }
  }
  &__content {
    padding: 30px;
    border: 1px solid var(--tp-border-primary);
    border-radius: 0 0 5px 5px;
    @media #{$xs} {
      padding: 30px 15px;
    }
    & p {
      font-size: 15px;
      color: var(--tp-text-2);
      margin-bottom: 25px;
    }
  }
  &__category {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: var(--tp-icon-blue);
    padding: 9px 18px;
    border-radius: 5px;
    background-color: var(--tp-icon-blue-light);
    line-height: 1;
    &:hover {
      background-color: var(--tp-icon-blue);
      color: var(--tp-common-white);
    }
  }
  &__title {
    & a {
      background-image: linear-gradient(#171151, #171151),
        linear-gradient(#171151, #171151);
      display: inline;
      background-size: 0% 1px, 0 1px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 0.4s linear;
      line-height: 1.4;
      & br {
        @media #{$xl,$md,$xs} {
          display: none;
        }
      }
    }
  }
  &__btn {
    & a {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      padding: 7px 20px;
      background-color: rgb(23, 17, 81, 0.1);
      border-radius: 5px;
      @media #{$md} {
        font-size: 12px;
        padding: 6px 12px;
      }
      &:hover {
        background-color: var(--tp-text-body);
        color: var(--tp-common-white);
      }
    }
  }
}
.tp-blog-arrow {
  justify-content: flex-end;
  @media #{$xs} {
    justify-content: start;
    margin-bottom: 30px;
  }
  & .tp-blog-p {
    margin-right: 20px;
  }
  & i {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid var(--tp-border-secondary);
    &:hover {
      background-color: var(--tp-theme-primary);
      color: var(--tp-common-white);
      border: 1px solid var(--tp-theme-primary);
    }
  }
}
.blog-item {
  @include transition(0.3s);
  &:hover {
    box-shadow: 0px 17px 16px rgba(216, 216, 216, 0.29);
    & .blog-item__thumb {
      & img {
        transform: scale(1.1);
      }
    }
    & .blog-item__title {
      color: var(--tp-icon-blue);
    }
  }
  &__thumb {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    & img {
      @include transition(0.8s);
      @media #{$lg} {
        width: 100%;
      }
    }
  }
  &__content {
    padding: 35px 25px 35px 25px;
    border: 1px solid var(--tp-border-primary);
    border-radius: 0 0 5px 5px;
    & p {
      font-size: 15px;
      color: var(--tp-text-2);
    }
    & a {
      background-image: linear-gradient(#0e63ff, #0e63ff),
        linear-gradient(#0e63ff, #0e63ff);
      display: inline;
      background-size: 0% 1px, 0 1px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 0.4s linear;
      &:hover {
        background-size: 0% 1px, 100% 1px;
      }
    }
  }
  &__date-info {
    & li {
      margin-right: 35px;
      font-size: 14px;
      font-weight: 700;
      @media #{$md,$xs} {
        margin-right: 25px;
      }
      & i {
        margin-right: 8px;
        color: var(--tp-theme-blue);
      }
    }
  }
}
.blog-arrow {
  justify-content: flex-end;
  @media #{$xs} {
    justify-content: start;
    margin-bottom: 30px;
  }
  & .blog-prv {
    margin-right: 20px;
  }
  & i {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid var(--tp-border-secondary);
    &:hover {
      background-color: var(--tp-theme-primary);
      color: var(--tp-common-white);
      border: 1px solid var(--tp-theme-primary);
    }
  }
}
.blog-link {
  & span {
    font-weight: 500;
    font-size: 18px;
    color: var(--tp-text-2);
    & a {
      margin-left: 8px;
      text-transform: uppercase;
      color: var(--tp-theme-blue);
      font-weight: 600;
      &:hover {
        color: var(--tp-icon-green);
        & i {
          animation: tfLeftToRight 0.5s forwards;
        }
      }
      & i {
        margin-left: 5px;
      }
    }
  }
}
.postbox {
  &__thumb {
    & img {
      border-radius: 10px;
    }
  }
  &__wrapper {
    @media #{$lg,$md,$xs} {
      padding-right: 0;
    }
  }
  &__meta {
    & span {
      margin-right: 55px;
      color: var(--tp-theme-primary);
      font-weight: 700;
      font-size: 14px;
      @media #{$lg} {
        margin-right: 18px;
      }
      @media #{$xs} {
        margin-right: 15px;
        font-size: 14px;
      }
      & i {
        margin-right: 5px;
        color: var(--tp-theme-blue);
      }
      & a {
        text-transform: uppercase;
        display: inline-block;
        &:hover {
          color: var(--tp-theme-blue);
        }
      }
    }
  }
  &__title {
    font-weight: 400;
    font-size: 36px;
    line-height: 1.25;
    @media #{$lg} {
      font-size: 30px;
    }
    @media #{$xs} {
      font-size: 24px;
    }
    &:hover {
      color: var(--tp-theme-secondary);
    }
  }
  &__text {
    & p {
      font-size: 16px;
      @media #{$xs} {
        font-size: 14px;
      }
    }
  }
  & .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 88px;
    width: 88px;
    background: var(--tp-common-white);
    text-align: center;
    line-height: 88px;
    border-radius: 50%;
    color: var(--tp-theme-secondary);
    animation: tp-pulse-2 1.5s infinite;
    &:hover {
      background-color: var(--tp-icon-blue);
      color: var(--tp-common-white);
    }
  }
  &__meta-img {
    & img {
      width: 100%;
      border-radius: 10px;
    }
  }
  &__tag {
    & span {
      font-size: 20px;
      font-family: var(--tp-ff-heading);
      margin-right: 15px;
    }
  }
  &__social-tag {
    & span {
      font-size: 20px;
      font-family: var(--tp-ff-heading);
      margin-right: 15px;
    }
    & a {
      margin-right: 30px;
      font-size: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__tag-border {
    border-top: 1px solid var(--tp-border-primary);
    border-bottom: 1px solid var(--tp-border-primary);
    padding: 30px 0;
  }
  &__social-tag {
    text-align: end;
    @media #{$lg,$md,$xs} {
      margin-top: 15px;
      text-align: start;
    }
  }
  &__content-area {
    & p {
      font-size: 16px;
      margin-bottom: 30px;
      @media #{$lg} {
        font-size: 15px;
      }
      & br {
        @media #{$lg,$md,$xs} {
          display: none;
        }
      }
    }
  }
  &__text-list {
    & ul {
      & li {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        & i {
          margin-right: 20px;
          font-size: 18px;
          color: var(--tp-theme-blue);
        }
      }
    }
  }
  &__comment {
    border-bottom: 1px solid var(--tp-border-primary);
    & ul {
      & li {
        margin-bottom: 10px;
        list-style: none;

        &.children {
          margin-left: 100px;
          margin-bottom: 30px;
          @media #{$xs} {
            margin-left: 0px;
          }
        }
      }
    }
    &-form {
      margin-bottom: 20px;
      &-title {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      & p {
        color: var(--tp-text-2);
        font-size: 16px;
        margin-bottom: 60px;
      }
    }
    &-input {
      position: relative;
      margin-bottom: 30px;
      color: var(--tp-theme-primary);
      & span {
        font-weight: 600;
        color: var(--tp-common-black);
        margin-bottom: 12px;
        display: block;
      }
      & input,
      & textarea {
        height: 60px;
        padding: 0 20px;
        width: 100%;
        font-size: 14px;
        color: var(--tp-theme-primary);
        outline: none;
        border: 1px solid var(--tp-border-primary);
        @include border-radius(7px);
        box-shadow: 0px 16px 24px rgba(189, 196, 205, 0.13);
        &:focus {
          border: 1px solid rgba(23, 17, 81, 1);
        }
        &::placeholder {
          color: var(--tp-text-2);
          &:focus {
            color: var(--tp-theme-primary);
          }
        }
      }
      & textarea {
        height: 175px;
        resize: none;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 35px;
    }
    &-box {
      margin-bottom: 70px;
    }
    &-avater {
      & img {
        width: 80px;
        height: 80px;
        @include border-radius(50%);
      }
    }
    &-name {
      margin-bottom: 15px;
      & h5 {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 8px;
        font-family: "Archivo";
        font-weight: 500;
      }
      & span {
        font-size: 14px;
        color: var(--tp-text-2);
        letter-spacing: 2.5px;
      }
    }
    &-text {
      @media #{$xs} {
        margin-left: 0;
        margin-top: 15px;
      }

      & p {
        font-size: 16px;
        color: var(--tp-text-2);
        margin-bottom: 15px;
        @media #{$lg} {
          font-size: 15px;
        }
        @media #{$xs} {
          font-size: 14px;
        }
        & br {
          @media #{$lg,$md,$xs} {
            display: none;
          }
        }
      }
    }
    &-reply {
      margin-top: 10px;
      & a {
        display: inline-block;
        color: var(--tp-theme-1);
        background: var(--tp-common-white);
        line-height: 22px;
        padding: 4px 10px;
        font-weight: 500;
        font-size: 14px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -o-border-radius: 4px;
        -ms-border-radius: 4px;
        border-radius: 4px;
        &:hover {
          color: var(--tp-common-white);
          background: var(--tp-theme-secondary);
        }
      }
    }
    &-agree {
      padding-left: 5px;
      & input {
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        display: block;
        width: 14px;
        height: 14px;
        background: var(--tp-common-white);
        border: 1px solid #b9bac1;
        outline: none;
        @include border-radius(4px);
        flex: 0 0 auto;
        @include transform(translateY(-1px));
        &:checked {
          position: relative;
          background-color: var(--tp-theme-1);
          border-color: transparent;
          &::after {
            box-sizing: border-box;
            content: "\f00c";
            position: absolute;
            font-family: var(--tp-ff-fontawesome);
            font-size: 10px;
            color: var(--tp-common-white);
            top: 46%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      & label {
        padding-left: 8px;
        color: var(--tp-text-1);
        line-height: 1;
        & a {
          color: var(--tp-common-black);
          font-weight: 600;
          padding-left: 4px;
          &:hover {
            color: var(--tp-theme-1);
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.sidebar {
  // @media #{$lg,$md,$xs} {
  //     padding-left: 0;
  // }
  &__wrapper {
    @media #{$lg,$md,$xs} {
      padding-left: 0;
    }
  }
  &__widget {
    padding: 50px 30px 40px 30px;
    box-shadow: 0px 12px 10px rgba(238, 239, 240, 0.25);
    border: 1px solid #f2f5fa;
  }
  &__widget-content {
    & ul {
      & li {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        & a {
          color: var(--tp-text-2);
          font-weight: 600;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #f2f5fa;
          padding: 9px 15px;
          &:hover {
            color: #171717;
          }
          & span {
            display: block;
            text-align: end;
          }
        }
      }
    }
  }
  &__widget-title {
    font-size: 22px;
  }
  &__search-input-2 {
    & input {
      height: 60px;
      width: 100%;
      padding: 10px 60px 10px 20px;
      border: none;
      background-color: var(--tp-grey-1);
      &::placeholder {
        font-weight: 500;
        font-size: 12px;
        color: var(--tp-text-2);
        background-color: var(--tp-grey-1);
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    & button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      padding: 16px 25px;
    }
  }
}
.rc {
  &__post-thumb {
    margin-right: 20px;
  }
  &__meta {
    & span {
      font-weight: 600;
      font-size: 13px;
      color: var(--tp-text-2);
      letter-spacing: 1px;
      display: block;
      margin-bottom: 5px;
    }
  }
  &__post-title {
    font-family: "Archivo";
    font-weight: 500;
    font-size: 16px;
    color: #141515;
    line-height: 1.5;
    @media #{$lg,$xs} {
      font-size: 15px;
    }
    &:hover {
      color: var(--tp-theme-secondary);
    }
  }
}
.tagcloud {
  & a {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--tp-text-2);
    border: 1px solid #f2f4f6;
    padding: 10px 26px;
    display: inline-block;
    line-height: 1;
    margin: 5px 10px 5px 0;
    &:hover {
      color: var(--tp-common-white);
      background-color: var(--tp-theme-secondary);
    }
  }
}
.postbox-nav {
  & button {
    position: absolute;
    left: 0px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    &.postbox-slider-button-next {
      left: auto;
      right: 0px;
    }
    & i {
      height: 60px;
      width: 60px;
      line-height: 60px;
      background-color: var(--tp-common-white);
      color: var(--tp-theme-primary);
      @media #{$xs} {
        height: 40px;
        width: 40px;
        line-height: 40px;
      }
      &:hover {
        background-color: var(--tp-theme-secondary);
        color: var(--tp-common-white);
      }
    }
  }
}
.blog-d-lnkd {
  color: #0072ac;
}
.blog-d-pin {
  color: #da0021;
}
.blog-d-fb {
  color: #0072ac;
}
.blog-d-tweet {
  color: #3ac4ff;
}
.blogthumb {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 440px;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    top: 0%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--tp-common-white);
    z-index: -1;
    @include transition(0.5s);
  }
  &:hover {
    &::before {
      background-color: #0e63ff;
      opacity: 0.8;
    }
    & .blogitem__avata-content {
      & span {
        color: var(--tp-common-white);
      }
      & a {
        color: var(--tp-common-white);
      }
    }
    & .blogitem__medi {
      & a {
        background-color: #467efb;
        color: var(--tp-common-white);
      }
    }
    &.pink-blog {
      & .blogitem__medi {
        & a {
          background-color: var(--tp-icon-pink);
          color: var(--tp-common-white);
        }
      }
    }
    &.green-blog {
      & .blogitem__medi {
        & a {
          background-color: var(--tp-icon-green);
          color: var(--tp-common-white);
        }
      }
    }
    & .blog-item__date-info {
      color: var(--tp-common-white);
      & i {
        color: var(--tp-common-white);
      }
    }
    & .blogitem__title {
      color: var(--tp-common-white);
    }
    & .blog-bg-btn {
      bottom: 0;
      opacity: 1;
      visibility: visible;
    }
    &.pink-blog {
      &::before {
        background-color: var(--tp-theme-pink);
      }
    }
    &.green-blog {
      &::before {
        background-color: var(--tp-theme-secondary);
      }
    }
  }
}
.inner-blog-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.blogitem {
  padding: 35px 30px 35px 30px;
  position: relative;
  height: 100%;
  @media #{$xs} {
    padding: 25px 15px 25px 20px;
  }
  &__title-area {
    transform: translateY(69px);
    @include transition(0.5s);
  }
  &:hover {
    & .blogitem__title-area {
      transform: inherit;
    }
  }
  &__avata-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__avata {
    display: flex;
    align-items: center;
    &-content {
      & span {
        display: block;
        font-size: 12px;
        color: var(--tp-text-2);
        line-height: 19px;
      }
      & a {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        @media #{$md} {
          font-size: 12px;
        }
      }
    }
    &-icon {
      margin-right: 15px;
      & img {
        border-radius: 50%;
      }
    }
  }
  &__medi {
    & a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: var(--tp-icon-blue);
      padding: 9px 18px;
      border-radius: 5px;
      background-color: var(--tp-icon-blue-light);
      line-height: 1;
    }
  }
  &__title {
    & a {
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#ffffff, #ffffff);
      display: inline;
      background-size: 0% 1px, 0 1px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 0.4s linear;
      line-height: 1.4;
      &:hover {
        background-size: 0% 1px, 100% 1px;
      }
    }
  }
}
.blog-bg-btn {
  @include transition(0.5s);
  & a {
    background-color: var(--tp-text-body);
    color: var(--tp-common-white);
  }
}
.blogitem__title {
  margin-bottom: 25px;
  font-size: 20px;
  @media #{$bxxxl,$xxl,$md,$xs} {
    font-size: 18px;
  }
}
.pink-blog {
  & .blogitem__medi {
    & a {
      background-color: var(--tp-icon-pink-light);
      color: var(--tp-icon-pink);
    }
  }
  & .blog-item__date-info {
    & i {
      color: var(--tp-theme-pink);
    }
  }
}
.green-blog {
  & .blogitem__medi {
    & a {
      background-color: var(--tp-icon-green-light);
      color: var(--tp-icon-green);
    }
  }
  & .blog-item__date-info {
    & i {
      color: var(--tp-theme-secondary);
    }
  }
}
.tp-blog-btn {
  text-align: end;
  @media #{$xs} {
    text-align: center;
  }
}
