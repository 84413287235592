@use "../utils" as *;

/*----------------------------------------*/
/*  19. SHOP CSS START
/*----------------------------------------*/

.tpshopitem {
  &:hover {
    & .tpshopitem__thumb {
      & img {
        transform: scale(1.2);
      }
    }
    & .tpshopitem__thumb-icon {
      bottom: 14px;
      opacity: 1;
      visibility: visible;
    }
  }
  &__thumb {
    border: 1px solid #eceff4;
    border-radius: 5px;
    & img {
      width: 100%;
      @include transition(1s);
    }
  }
  &__thumb-icon {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    @include transition(0.3s);
    & a {
      margin: 0 5px;
      height: 45px;
      width: 45px;
      line-height: 45px;
      border: 1px solid var(--tp-theme-blue);
      border-radius: 50%;
      display: inline-block;
      background-color: #0e63ff;
      color: var(--tp-common-white);
      &:hover {
        background-color: var(--tp-theme-pink);
        border: 1px solid var(--tp-theme-pink);
      }
    }
  }
  &__content {
    & p {
      font-size: 18px;
      font-weight: 500;
      color: #777b83;
      margin-bottom: 6px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 500;
    display: block;
    & a {
      background-image: linear-gradient(#0e63ff, #0e63ff),
        linear-gradient(#0e63ff, #0e63ff);
      background-repeat: no-repeat;
      background-position: 100% 100%, 0% 100%;
      background-size: 0% 1px, 0 1px;
      display: inline;
      &:hover {
        background-size: 0% 1px, 100% 1px;
        color: var(--tp-theme-blue);
      }
    }
  }
  &__review {
    & i {
      color: #f7931e;
    }
  }
  &__product-base {
    position: absolute;
    top: 20px;
    left: 30px;
    font-weight: 700;
    font-size: 12px;
    color: var(--tp-common-white);
    padding: 6px 13px;
    background: #fd4766;
    border-radius: 5px;
    line-height: 1;
  }
}
.tpproduct {
  & span {
    font-weight: 500;
    font-size: 15px;
  }
}
.tpfilter {
  justify-content: flex-end;
  @media #{$xs} {
    justify-content: start;
  }
  & span {
    font-weight: 500;
    font-size: 15px;
  }
  & .nice-select {
    padding-left: 5px;
    border: none;
    color: var(--tp-theme-primary);
  }
}

// shop-details
.productthumb {
  & img {
    border-radius: 25px;
    @media #{$xs,$md} {
      max-width: 100%;
    }
  }
}
.product {
  @media #{$md,$xs} {
    margin-left: 0;
  }
  & {
    &__details-content {
      & p {
        font-weight: 400;
        font-size: 16px;
        color: #777b83;
        & br {
          @media #{$xs,$md} {
            display: none;
          }
        }
      }
    }
  }
  &-dtitle {
    font-weight: 400;
    font-size: 30px;
    line-height: 1.3;
    @media #{$xs} {
      font-size: 24px;
    }
  }
  &-dinfo {
    & span {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #141515;
    }
  }
  &-rating {
    & a {
      color: #ffdc60;
    }
  }
  &-dprice {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.02em;
  }
  &-model-title {
    font-family: "Archivo";
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #0b0b0b;
  }
  &-quantity-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #0b0b0b;
  }
  &-quantity {
    input {
      color: var(--tp-theme-primary);
      border: none;
      width: 40px;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
    }
  }
  &-model-list {
    & a {
      font-size: 12px;
      font-weight: 600;
      color: #777b83;
      background: #f7f8fd;
      display: inline-block;
      padding: 0 10px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-right: 15px;
      border-radius: 2px;
      @media #{$md} {
        margin-right: 8px;
      }
      @media #{$xs} {
        margin-right: 5px;
        padding: 0 6px;
      }
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: var(--tp-theme-blue);
        color: var(--tp-common-white);
      }
    }
  }
}
.tp-product-quantity {
  & .cart-minus,
  & .cart-plus {
    cursor: pointer;
    color: #8d8884;
    &:hover {
      color: var(--tp-theme-primary);
    }
  }
  & input {
    height: 30px;
    width: 32px;
    font-size: 14px;
    border: none;
    font-weight: 700;
    text-align: center;
  }
}
.product-rating {
  margin-right: 20px;

  & a {
    font-size: 16px;
    color: #ffdc60;
  }
}
.product-dinfo {
  display: flex;
}
.product-dprice {
  & del {
    font-size: 20px;
    color: #b9b9b9;
  }
}
.pro-details-nav {
  background: #f9fafb;
  display: inline-block;
  padding: 7px 7px;
}
.pro-details-nav-btn {
  border: none;
  & li {
    font-weight: 500;
    font-size: 18px;
    color: var(--tp-text-body);
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 40px;
      width: 1px;
      background: #dfe3e9;
      top: 50%;
      transform: translateY(-50%);
      @media #{$xs} {
        display: none;
      }
    }
    &::before {
      content: "";
      height: 100%;
      width: 101%;
      top: 0;
      left: -1px;
      background: #0e63ff;
      position: absolute;
      z-index: 3;
      opacity: 0;
      visibility: hidden;
    }
    .active::before {
      opacity: 1;
      visibility: visible;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    @media #{$xs} {
      font-size: 16px;
    }
    & .nav-links {
      padding: 20px 45px;
      border-radius: 5px;
      @media #{$md} {
        padding: 10px 20px;
      }
      @media #{$xs} {
        padding: 5px 15px;
      }
      &.active {
        background: var(--tp-theme-blue);
        color: var(--tp-common-white);
      }
    }
  }
}
.tab-para {
  & p {
    font-size: 16px;
    line-height: 30px;
  }
}
.comments-box {
  margin-bottom: 50px;
}
.user-rating {
  & ul {
    & li {
      display: inline-block;
      color: #ffdc60;
    }
  }
}
.comments-text {
  & span,
  p {
    color: #777777;
    font-size: 16px;
  }
}
.comment-title {
  & p {
    color: #777777;
    font-size: 16px;
  }
}
.comment-rating {
  & span {
    font-weight: 500;
    margin-right: 5px;
  }
  & ul {
    & li {
      display: inline-block;
      color: #ffdc60;
    }
  }
}
.comment-input {
  & textarea {
    width: 100%;
    height: 120px;
    margin-bottom: 20px;
    border: 1px solid #e7e7e7;
    padding: 20px;
    &:focus-visible {
      border: 1px solid transparent;
    }
  }
  & input {
    width: 100%;
    height: 50px;
    resize: none;
    outline: 0;
    border: 1px solid #e7e7e7;
    padding: 8px 20px;
    margin-bottom: 30px;
    &:focus {
      border: 1px solid var(--tp-theme-blue);
    }
  }
}
.comments-avatar {
  flex: 0 0 auto;
}
.table .add-info {
  font-size: 18px;
  font-weight: 500;
}
.table {
  & td {
    padding: 18px;
  }
}
.shop-left-right {
  @media #{$xxl,$xl,$lg,$md,$xs} {
    margin-left: 0;
    margin-right: 0;
  }
}
.tp-comments-title {
  font-size: 24px;
  @media #{$xs} {
    font-size: 18px;
  }
}
.avatar-name {
  & b {
    font-size: 16px;
    font-weight: 500;
  }
}
.shop-faq {
  & .accordion-header {
    & button {
      font-size: 18px;
      padding: 20px 55px 20px 30px;
      @media #{$xs} {
        font-size: 14px;
      }
    }
  }
  & .accordion-content {
    & p {
      & br {
        display: none;
      }
    }
  }
  & .accordion-content {
    & p {
      padding: 0 75px 20px 30px;
    }
  }
}
.product-button {
  & a {
    @media #{$md} {
      margin-bottom: 10px;
    }
  }
}
