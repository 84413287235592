@use "../utils" as *;

.header {
  &__area {
    padding: 40px 90px 40px 80px;
    position: relative;
    z-index: 2;
    @media #{$xxl,$xl} {
      padding: 40px 15px;
    }
  }
}
.main-menu {
  margin: 0 0 0 60px;
  @media #{$xl} {
    margin: 10px 0 0 0;
  }
  & ul {
    & > li {
      display: inline-block;
      position: relative;
      z-index: 9;
      & > a {
        padding: 15px 0;
        margin: 0 30px;
        font-size: 14px;
        color: var(--tp-text-2);
        font-weight: 700;
        display: block;
        text-transform: uppercase;
        &:hover,
        &.active {
          color: var(--tp-text-body);
        }
        @media #{$xxl} {
          font-size: 12px;
        }
        @media #{$xxl,$xl} {
          margin: 0 20px;
        }
        @media #{$xl} {
          font-size: 12px;
        }
      }
      &.has-dropdown {
        & a {
          position: relative;
          &::after {
            content: "+";
            transform: translateY();
            right: 0;
          }
        }
      }
      & .sub-menu {
        position: absolute;
        top: 120%;
        left: 0;
        width: 240px;
        padding: 20px 30px;
        background: var(--tp-theme-primary);
        opacity: 0;
        visibility: hidden;
        @include transition(0.3s);
        & > li {
          display: block;
          width: 100%;
          margin: 0;
          & > a {
            display: block;
            font-weight: 500;
            padding: 5px 5px;
            font-size: 14px;
            z-index: 1;
            color: var(--tp-common-white);
            width: 100%;
            text-transform: capitalize;
            margin: 0;
            @include transition(0.3s);
            &:hover {
              color: var(--tp-theme-secondary);
            }
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
      &:hover {
        & .sub-menu {
          visibility: visible;
          opacity: 1;
          top: 100%;
        }
      }
    }
  }
}

// header-area-secondary
.header-custom {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  border-bottom: 1px solid var(--tp-border-primary);
}
.header-logo-box {
  flex: 0 0 auto;
  width: 345px;
  text-align: center;
  & img {
    width: 220px;
  }
}
.header-menu-box {
  width: 100%;
  border-left: 1px solid var(--tp-border-primary);
}
.header-menu-top {
  border-bottom: 1px solid var(--tp-border-primary);
}
.header-time {
  text-align: right;
  & span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--tp-text-2);
    margin-left: 50px;
    margin-right: 30px;
    @media #{$xl} {
      margin-left: 15px;
      margin-right: 15px;
    }
    & i {
      color: var(--tp-text-body);
      font-size: 14px;
      margin-right: 8px;
    }
  }
}
.header-top-mob {
  margin-left: 80px;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  color: var(--tp-text-2);
  padding: 22px 0;
  @media #{$xl} {
    margin-left: 30px;
  }
  & span {
    margin-left: 5px;
  }
  & a {
    color: var(--tp-text-body);
    &:hover {
      color: var(--tp-icon-green);
    }
  }
}
.main-menu-second {
  margin: 0 0 0 80px;
  @media #{$xl} {
    margin: 0 0 0 30px;
  }
}
.header-cart-list {
  text-align: end;
  @media #{$xl} {
    margin-right: 25px;
  }
}
.header-bottom-btn {
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  padding: 25px 45px;
  background-color: #0e63ff;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  color: var(--tp-common-white);
  position: relative;
  overflow: hidden;
  z-index: 1;
  @media #{$md,$xs} {
    padding: 20px 40px;
  }
  &::after {
    content: "";
    height: 300px;
    width: 300px;
    background-color: #2974ff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &::after {
      transform: translateY(-50%) translateX(-50%) scale(1);
    }
  }
  @media #{$xl} {
    padding: 25px 30px;
  }
}
.main-menu-second {
  & ul {
    & li {
      margin-right: 60px;
      &:last-child {
        margin-right: 0;
      }
      @media #{$xxxl} {
        margin-right: 45px;
      }
      @media #{$xxl} {
        margin-right: 30px;
      }
      @media #{$xl} {
        margin-right: 20px;
      }
      & > a {
        margin: 0;
        padding: 20px 0;
        &::before {
          content: "";
          bottom: 1px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 0%;
          height: 2px;
          background-color: var(--tp-icon-green);
          position: absolute;
        }
        &:hover,
        &.active {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}

// mean menu
.tp-menu-toggle {
  font-size: 16px;
  line-height: 0;
  @media #{$xl} {
    margin-right: 25px;
  }
  @media #{$lg,$md,$xs} {
    font-size: 28px;
  }
}
.tp-side-info-area {
  background: #171151;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 30px;
  width: 350px;
  transform: translateX(120%);
  transition: 0.3s;
  z-index: 99;
  &.tp-sidebar-opened {
    transform: translateX(0);
  }
  @media #{$xs} {
    width: 300px;
  }
}
.tp-sidebar-close {
  color: #fff;
  position: absolute;
  left: -35px;
  font-size: 21px;
  background: #171151;
  width: 35px;
  height: 35px;
}
.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999999;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  &.opened {
    opacity: 1;
    visibility: visible;
  }
}

// header-menu
.layout-left-right {
  display: flex;
  flex: 0 0 auto;
  @media #{$lg,$md,$xs} {
    display: block;
  }
}
.header-layout-left {
  width: 350px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  @media #{$bxxxl} {
    width: 300px;
  }
  @media #{$bxxl} {
    width: 250px;
  }
}
.content-layout-right {
  width: 100%;
}
.content-layout-right {
  width: calc((100% - 350px));
  margin-left: auto;
  @media #{$bxxxl} {
    width: calc((100% - 300px));
  }
  @media #{$bxxl} {
    width: calc((100% - 250px));
  }
  @media #{$lg,$md,$xs} {
    width: 100%;
  }
}
.header-left-logo {
  @media #{$xxxl,$bxxxl} {
    padding-bottom: 40px;
  }
  @media #{$xxl,$xl} {
    padding-bottom: 20px;
  }
  & a {
    margin: 70px 63px;
    display: block;
    @media #{$xxxl,$bxxxl} {
      margin: 35px 30px;
    }
    @media #{$xxl,$xl} {
      margin: 35px 25px;
    }
    @media #{$xxl,$xl} {
      margin: 35px 25px;
      text-align: center;
    }
    & img {
      @media #{$xxl,$xl} {
        width: 150px;
      }
    }
  }
}
.header-left-menu {
  margin: 0 0 140px 100px;
  border-left: 2px solid #201b58;
  @media #{$xxxl,$bxxxl} {
    margin: 0 0 90px 80px;
  }
  @media #{$xxl,$xl} {
    margin: 0 0 60px 60px;
  }
  & ul {
    & > li {
      margin-bottom: 40px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &.has-dropdown {
        & > a {
          &::after {
            content: "+";
            right: 0;
          }
        }
      }
      & a {
        color: var(--tp-common-white);
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding: 0 22px;
        display: block;
        position: relative;
        &::before {
          content: "";
          left: -2px;
          top: 0;
          bottom: 0;
          margin: 0 auto;
          width: 2px;
          height: 0px;
          background-color: var(--tp-icon-green);
          position: absolute;
        }
        &:hover {
          color: var(--tp-theme-secondary);
          &::before {
            height: 100%;
          }
        }
      }
      &:hover {
        & .sub-menu {
          opacity: 1;
          visibility: visible;
          left: 100%;
        }
      }
    }
  }
  & .sub-menu {
    position: absolute;
    top: 0%;
    left: 105%;
    width: 200px;
    padding: 20px 10px;
    background: #29226a;
    transition: all 0.3s ease-out 0s;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    & li {
      margin-bottom: 8px;
      & a {
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
}
.header-left-contact {
  text-align: center;
  & span {
    font-weight: 700;
    font-size: 14px;
    color: var(--tp-theme-secondary);
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
  }
  & a {
    font-family: "Days One";
    font-weight: 400;
    font-size: 30px;
    color: var(--tp-common-white);
    @media #{$xxl,$xl} {
      font-size: 24px;
    }
    &:hover {
      color: var(--tp-theme-secondary);
    }
  }
}
.hero-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  height: 900px;
  @media #{$xxxl} {
    height: 750px;
  }
  @media #{$xs} {
    height: 650px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      89.9deg,
      #0055f1 -6.67%,
      rgba(23, 17, 81, 0) 73.29%
    );
    z-index: -1;
  }
}
.tpherobg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  & img {
    max-width: 100%;
  }
}
.tpherotext {
  padding-top: 275px;
  @media #{$xxxl,$bxxxl,$xxl,$xl} {
    padding-top: 150px;
    padding-left: 40px;
  }
  @media #{$lg,$md,$xs} {
    padding-top: 180px;
  }
  &__title {
    font-weight: 400;
    font-size: 60px;
    line-height: 1.16;
    color: var(--tp-common-white);
    @media #{$xs} {
      font-size: 36px;
      margin-bottom: 20px;
    }
    & br {
      @media #{$xs} {
        display: none;
      }
    }
  }
  & p {
    font-weight: 500;
    font-size: 18px;
    color: var(--tp-common-white);
    margin-bottom: 50px;
    @media #{$xs} {
      font-size: 16px;
    }
    & br {
      @media #{$xs} {
        display: none;
      }
    }
  }
}
.tp-common-area {
  @media #{$bxxxl} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media #{$xxl} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media #{$xl} {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.tp-mob-logo {
  & img {
    width: 220px;
    @media #{$xs,$md} {
      width: 180px;
    }
  }
}
.logo {
  & img {
    width: 220px;
  }
}
.footer-widget {
  &__title {
    & img {
      width: 220px;
    }
  }
}

// tpsideinfo
.tpsideinfo {
  background-color: var(--tp-theme-primary);
  text-align: start;
  position: fixed;
  right: 0;
  top: 0;
  width: 420px;
  height: 100%;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999999;
  padding: 30px;
  transform: translateX(100%);
  overflow-y: scroll;
  @media #{$xs} {
    width: 280px;
  }
  &.tp-shop-sidebar-opened {
    transform: translateX(0);
  }
  &__close {
    color: #fff;
    right: 30px;
    font-size: 18px;
    background: var(--tp-theme-secondary);
    width: 35px;
    height: 35px;
    position: absolute;
    top: 40px;
    @media #{$xs} {
      width: 30px;
      height: 30px;
    }
  }
  &__logo {
    & a {
      & img {
        width: 180px;
        @media #{$xs} {
          width: 160px;
        }
      }
    }
  }
  &__content {
    @media #{$lg,$md,$xs} {
      padding-top: 35px;
      margin-bottom: 40px;
    }
    & p {
      font-weight: 500;
      font-size: 18px;
      color: var(--tp-common-white);
      margin-bottom: 55px;
    }
    & span {
      color: var(--tp-text-2);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 14px;
      display: block;
      margin-bottom: 8px;
    }
    & a {
      display: block;
      color: var(--tp-common-white);
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
      @media #{$xs} {
        font-size: 14px;
      }
      &:hover {
        color: var(--tp-theme-secondary);
      }
      & i {
        color: #ffdc60;
        margin-right: 5px;
      }
    }
    &-inputarea {
      & span {
        color: var(--tp-text-2);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
      }
      &-input {
        position: relative;
        & input {
          width: 100%;
          height: 60px;
          padding: 0 80px 0 25px;
          font-size: 16px;
          color: var(--tp-theme-primary);
          border: none;
          background-color: var(--tp-common-white);
          border-radius: 5px;
          border: 1px solid var(--tp-text-2);
        }
        &-btn {
          position: absolute;
          color: var(--tp-common-white);
          height: 60px;
          width: 60px;
          background-color: var(--tp-icon-green);
          border-radius: 5px;
          top: 0;
          right: 0;
          &:hover {
            background-color: var(--tp-icon-pink);
          }
        }
      }
    }
  }
  &__gallery {
    & span {
      color: var(--tp-text-2);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 14px;
      display: block;
      margin-bottom: 10px;
    }
    &-item {
      & img {
        width: 95px;
        height: 95px;
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
  &__socialicon {
    & a {
      color: var(--tp-common-white);
      margin-right: 20px;
      font-size: 14px;
      &:hover {
        color: var(--tp-theme-secondary);
      }
    }
  }
}

.tpcarticon {
  background: #fff;
  opacity: 0;
  padding: 25px;
  right: -20px;
  top: 110%;
  transition: all 0.5s ease 0s;
  width: 350px;
  z-index: 9;
  box-shadow: 0px 12px 24px 0px rgba(120, 120, 120, 0.3);
  visibility: hidden;
  transform: translateY(10px);
  position: absolute;
  text-align: start;
  & ul {
    & li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__img {
    & img {
      flex: 0 0 auto;
      width: 85px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__content {
    width: 180px;
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__content-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    &:hover {
      color: var(--tp-theme-secondary);
    }
  }
  &__cart-price {
    & .new {
      color: var(--tp-theme-primary);
    }
    & span {
      font-weight: 600;
      color: var(--tp-text-2);
    }
  }
  &__del {
    & a {
      color: var(--tp-theme-primary);
      &:hover {
        color: var(--tp-theme-pink);
      }
    }
  }
  &__total-price {
    border-top: 1px solid #cacadb;
    overflow: hidden;
    padding-top: 25px;
    margin-top: 10px;
    & span {
      color: #747691;
      font-weight: 500;
    }
  }
  &__checkout-link {
    & a {
      background-color: var(--tp-theme-blue);
      color: #fff;
      display: block;
      font-weight: 500;
      padding: 16px 30px;
      text-align: center;
      font-size: 13px;
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 2px;
      &:hover {
        background-color: var(--tp-theme-pink);
      }
    }
    & .sec-red-btn {
      background-color: var(--tp-icon-green);
      &:hover {
        background-color: var(--tp-theme-pink);
      }
    }
  }
}
.tp-cart-icon-area {
  position: relative;
  padding: 18px 0;
  &:hover {
    & .tpcarticon {
      opacity: 1;
      visibility: visible;
      transform: translateY(-5px);
      z-index: 9;
    }
  }
}
.mean-container {
  overflow: hidden;
}
.tp-home-one {
  position: absolute;
  left: 0;
  right: 0;
}

// header-sticky
.header-sticky {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  background: var(--tp-common-white);
  & .tp-bt-btn {
    box-shadow: rgba(100, 100, 111, 0.1) 1px 5px 20px 1px;
  }
}
.tp-home-one {
  &.header-sticky {
    padding-top: 0px;
    padding-bottom: 0px;
    & .main-menu {
      & nav > {
        & ul > {
          & li > {
            & a {
              padding: 40px 0;
            }
          }
        }
      }
    }
  }
}
.tp-mobile-header-area {
  &.header-sticky {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
    z-index: 99;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    background: var(--tp-common-white);
    & .tp-bt-btn {
      box-shadow: rgba(100, 100, 111, 0.1) 1px 5px 20px 1px;
    }
  }
}
