@use '../utils' as *;

/*----------------------------------------*/
/*  16. RESEARCH CSS START
/*----------------------------------------*/

.research-item {
    &:hover {
        & .research-item__thum {
            & img {
                transform: scale(1.1);
            }
        }
    }
    &__title {
        & a {
            background-image: linear-gradient(#171151, #171151), linear-gradient(#171151, #171151);
            display: inline;
            background-size: 0% 1px, 0 1px;
            background-position: 100% 100%, 0 100%;
            background-repeat: no-repeat;
            transition: background-size 0.4s linear;
            &:hover {
                background-size: 0% 1px, 100% 1px;
            }
        }
    }
    &__thum {
        & img {
            @include transition(2s);
            width: 100%;
        }
    }
    &__content {
        & span {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            color: var(--tp-theme-blue);
            letter-spacing: .7px;
            display: block;
            margin-bottom: 20px;
        }
        & p {
            font-size: 15px;
            margin-bottom: 25px;
            @media #{$lg} {
               font-size: 14px; 
            }
        }
    }
    &__btn {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        padding: 5px 20px;
        color: var(--tp-theme-blue);
        background-color: var(--tp-icon-blue-light);
        text-transform: uppercase;
        border-radius: 5px;
        &:hover {
            color: var(--tp-common-white);
            background-color: var(--tp-theme-blue);
        }
    }
}
.tp-pink {
    & span {
        color: var(--tp-theme-pink);
    }
    & .research-item__btn {
        color: var(--tp-theme-pink);
        background: var(--tp-icon-pink-light);
        &:hover {
            color: var(--tp-common-white);
            background-color: var(--tp-theme-pink);
        }
    }
}
.tp-green {
    & span {
        color: var(--tp-theme-secondary);
    }
    & .research-item__btn {
        color: var(--tp-theme-secondary);
        background: var(--tp-icon-green-light);
        &:hover {
            color: var(--tp-common-white);
            background-color: var(--tp-theme-secondary);
        }
    }
}
.tp-sky {
    & span {
        color: var(--tp-theme-sky);
    }
    & .research-item__btn {
        color: var(--tp-theme-sky);
        background: var(--tp-icon-sky-light);
        &:hover {
            color: var(--tp-common-white);
            background-color: var(--tp-theme-sky);
        }
    }
}