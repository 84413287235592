$color_1: var(--black);
$color_2: var(--text);
$color_3: #fff;
$background-color_1: #fff;
$background-color_2: var(--black);
$background-color_3: #000000;

.contact-one {
	.inner-container {
		background-color: $background-color_1;
		position: relative;
	}
}
.contact-one__form {
	margin-top: 50px;
	h3 {
		margin: 0;
		color: $color_1;
		font-size: 30px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	.form-group {
		position: relative;
		margin-bottom: 20px;
		input[type="text"] {
			width: 100%;
			height: 55px;
			display: block;
			border: none;
			outline: none;
			border-bottom: 1px solid #dddddd;
			display: block;
			color: $color_2;
			font-size: 16px;
			background-image: none !important;
		}
		textarea {
			width: 100%;
			height: 55px;
			display: block;
			border: none;
			outline: none;
			border-bottom: 1px solid #dddddd;
			display: block;
			color: $color_2;
			font-size: 16px;
			background-image: none !important;
			height: 130px;
			padding-top: 10px;
		}
		input[type="email"] {
			width: 100%;
			height: 55px;
			display: block;
			border: none;
			outline: none;
			border-bottom: 1px solid #dddddd;
			display: block;
			color: $color_2;
			font-size: 16px;
			background-image: none !important;
		}
		i {
			position: absolute;
			top: 15px;
			right: 0;
			font-size: 16px;
			color: $color_2;
		}
	}
	&::placeholder {
		opacity: 1;
		color: $color_2;
	}
	.thm-btn {
		padding: 15.5px 38px;
	}
}
.contact-one__contact-page {
	padding-bottom: 120px;
}
.contact-one__person {
	margin-top: 50px;
}
.contact-one__top {
	background-color: $background-color_2;
	text-align: center;
	padding-top: 40px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $background-color_3;
		opacity: 0.33;
	}
	.container {
		position: relative;
	}
	.block-title {
		h3 {
			color: $color_3;
			padding-left: 20px;
			padding-right: 20px;
		}
		p {
			color: $color_3;
		}
	}
}
.contact-one__top-two {
	padding-top: 300px;
}
@media (min-width: 992px) {
	.contact-one {
		.inner-container {
			padding-left: 40px;
			padding-right: 40px;
			margin-top: -275px;
			position: relative;
			z-index: 10;
		}
	}
	.contact-one__form {
		margin-top: 0;
		padding-bottom: 40px;
		padding-left: 30px;
		h3 {
			font-size: 40px;
			margin-bottom: 55px;
			padding-top: 40px;
		}
		.thm-btn {
			margin-top: 30px;
		}
	}
	.contact-one__person {
		position: absolute;
		bottom: 0;
		left: 0px;
		margin-top: 0;
	}
	.contact-one__top {
		text-align: left;
		padding-top: 100px;
		padding-bottom: 300px;
		.block-title {
			p {
				justify-content: flex-start;
			}
			padding-left: 40px;
			h3 {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
