//
// _nft-explore.scss
//
@use '../utils' as *;

.bookmark-icon {
    .btn {
        color: #bfc8e2;
        background-color: #13D9A8;
        box-shadow: 0 1px 2px rgba(56,65,74,0.15);
        font-size: 0.8512rem;
        &:hover,
        &.active {
            border-color: transparent;
            color: #ee6352;
        }
    }
}

.explore-box {
    border-radius: 9px;
    overflow: hidden;
    border: none !important;
    box-shadow: 0 1px 2px rgba(56,65,74,0.15);
    .explore-img {
        height: 280px;
        object-fit: cover;
    }
    .card-animate {
        .card-content-wrapper {
          display: flex;
          flex-wrap: nowrap; // Prevents wrapping of items
        }
    }
    .explore-place-bid-img {
        position: relative;
        overflow: hidden;
        z-index: 0;
        flex-basis: 200px; // Sets a base width for the image container
        max-width: 200px; // Ensures that the width doesn't exceed 200px
        min-width: 200px;
        .bg-overlay {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(black, 0.4);
            opacity: 0;
            transition: all 0.5s ease;
        }
    
        .place-bid-btn {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
    
            .btn {
                opacity: 0;
                bottom: -25px;
                transition: 0.5s ease;
            }
        }
    }
    .card-info {
        flex-grow: 1;
    }
    
    &:hover {
        .explore-place-bid-img {
            .place-bid-btn {
                .btn {
                    opacity: 1;
                    bottom: 0;
                }
            }
    
            .bg-overlay {
                opacity: 1;
            }
        }
    }
    .discount-time {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-color: rgba(white, 0.4);
        backdrop-filter: blur(5px);
        padding: 8px;
    }
}

.filter {
    &__item {
      border: 1px solid var(--tp-border-primary);
      padding: 55px 40px 50px 55px;
      border-radius: 10px;
      @include transition(0.4s);
      @media #{$bxxxl} {
        padding: 75px 30px 70px 30px;
      }
      @media #{$xxl} {
        padding: 75px 20px 70px 25px;
      }
      @media #{$xl} {
        padding: 75px 20px 70px 25px;
      }
      &:hover {
        &.counter__item {
          border: 1px solid var(--tp-icon-blue);
        }
        &.blue-border {
          border: 1px solid var(--tp-icon-blue);
        }
        &.pink-border {
          border: 1px solid var(--tp-icon-pink);
        }
        &.sky-border {
          border: 1px solid var(--tp-icon-sky);
        }
        &.green-border {
          border: 1px solid var(--tp-icon-green);
        }
      }
    }
}

//
.nft-hero {
    // background-image: url('../../images/nft/bg-home.jpg');
    background-size: cover;
    background-position: bottom;
    padding: 222px 0 150px 0;
    .bg-overlay {
        background-color: #05175f;
        opacity: 0.85;
    }
}

#explorecard-list {
    .list-element {
        display: none;

        &:nth-child(-n+10) {
            display: block;
        }
    }
}

.card-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}
.filter-btn {
    @media #{$xs} {
        margin-right: 5px;
    }
}
.filter-btn-second {
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
    padding: 18px 32px;
    background-color: #13D9A8;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--tp-common-white);
    position: relative;
    overflow: hidden;
    z-index: 1;
    @media #{$md,$xs} {
        padding: 20px 40px;
    }
    &::after {
        content: "";
        height: 300px;
        width: 300px;
        background-color: #2974FF;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0);
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        z-index: -1;
    }
    &:hover{
        color:#fff;
        &::after {
            transform: translateY(-50%) translateX(-50%) scale(1);
        }
    }
}

.form-control {
    font-size: 15px !important;
}