@use "../utils" as *;

/*----------------------------------------*/
/*  22. FOOTER CSS START
/*----------------------------------------*/
.footer-widget {
  &__title {
    color: var(--tp-common-white);
    font-size: 24px;
    @media #{$xl} {
      font-size: 22px;
    }
  }
  & p {
    color: var(--tp-text-1);
    font-size: 15px;
    margin-bottom: 35px;
  }
  &__social {
    & a {
      margin-right: 12px;
      & i {
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        color: var(--tp-text-1);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:hover {
        & i {
          color: var(--tp-common-white);
          border: 1px solid var(--tp-icon-red);
          background-color: var(--tp-icon-red);
        }
      }
    }
  }
  &__links {
    & ul {
      & li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        & a {
          font-weight: 500;
          color: var(--tp-text-1);
          &:hover {
            color: var(--tp-icon-green);
          }
        }
      }
    }
  }
  &__info {
    & ul {
      & li {
        margin-bottom: 5px;
        color: var(--tp-text-1);
        font-weight: 500;
        &:last-child {
          margin-bottom: 0;
        }
        & a {
          font-weight: 500;
          color: var(--tp-text-1);
          &:hover {
            color: var(--tp-icon-green);
          }
        }
      }
    }
  }
  &__newsletter {
    & form {
      & input {
        width: 100%;
        height: 60px;
        padding: 0 200px 0 25px;
        font-size: 16px;
        color: var(--tp-text-1);
        border: none;
        background-color: #27225d;
        border-radius: 5px;
        border: 1px solid #312a70;
        @media #{$xl,$xs} {
          padding: 0 80px 0 25px;
        }
        &::placeholder {
          color: var(--tp-text-1);
          font-size: 15px;
        }
      }
    }
  }
  &__fw-news-btn {
    color: var(--tp-common-white);
    height: 60px;
    width: 60px;
    background: var(--tp-icon-green);
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
      background-color: var(--tp-icon-pink);
    }
  }
  &__copyright {
    @media #{$md,$xs} {
      text-align: center;
    }
    & span {
      color: var(--tp-common-white);
      font-size: 15px;
      font-weight: 500;
      @media #{$xs} {
        margin-bottom: 10px;
        display: block;
      }
      & a {
        &:hover {
          color: var(--tp-icon-green);
        }
      }
      & i {
        font-style: normal;
      }
    }
  }
  &__copyright-info {
    & ul {
      & li {
        margin-right: 40px;
        @media #{$xl} {
          margin-right: 30px;
        }
        @media #{$xs} {
          margin-right: 15px;
        }
        &:last-child {
          margin-right: 0;
        }
        & a {
          font-weight: 500;
          color: var(--tp-text-1);
          &:hover {
            color: var(--tp-icon-green);
          }
        }
      }
    }
  }
}
.footer-area-bottom {
  padding: 30px 0;
  border-top: 1px solid #251f5b;
}
.footer-white-content {
  border-left: 1px solid #251f5b;
}
.footer-bottom-border {
  padding: 30px 0;
  border-top: 1px solid #251f5b;
}
.info-direction {
  & ul {
    justify-content: flex-end;
    flex-wrap: wrap;
    @media #{$md} {
      justify-content: center;
    }
    @media #{$xs} {
      justify-content: center;
      margin-top: 15px;
    }
  }
}
.tp-f-youtube,
.tp-f-twitter,
.tp-f-fb,
.tp-f-skype {
  margin-right: 12px;
  & i {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    border: 1px solid var(--tp-border-primary);
    &:hover {
      color: var(--tp-common-white);
      border: 1px solid var(--tp-icon-red);
      background-color: var(--tp-icon-red);
    }
  }
}
.tp-f-twitter {
  &:hover {
    &.tp-f-twitter i {
      color: var(--tp-common-white);
      border: 1px solid var(--tp-icon-tweet);
      background-color: var(--tp-icon-tweet);
    }
  }
}
.tp-f-fb {
  &:hover {
    &.tp-f-fb i {
      color: var(--tp-common-white);
      border: 1px solid var(--tp-icon-fb);
      background-color: var(--tp-icon-fb);
    }
  }
}
.tp-f-skype {
  &:hover {
    &.tp-f-skype i {
      color: var(--tp-common-white);
      border: 1px solid var(--tp-icon-skype);
      background-color: var(--tp-icon-skype);
    }
  }
}
.footer-col-2 {
  margin-left: 95px;
  @media #{$xs} {
    margin-left: 0;
  }
}
.footer-col-3 {
  margin-left: 20px;
  @media #{$md,$xs} {
    margin-left: 0;
  }
}
.footer-col-4 {
  margin-left: -22px;
  @media #{$lg,$xs} {
    margin-left: 0;
  }
}
.tp-footer-widget {
  & i {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: var(--tp-text-2);
    margin-bottom: 10px;
    display: inline-block;
  }
  &__content {
    @media #{$xs} {
      margin-bottom: 25px;
    }
    & > a {
      font-weight: 500;
      font-size: 18px;
    }
  }
  &__contact {
    font-size: 36px;
    @media #{$lg} {
      font-size: 30px;
    }
  }
  &__sub-sec {
    & p {
      color: var(--tp-text-2);
      & br {
        @media #{$lg,$xs} {
          display: none;
        }
      }
    }
  }
  &__sub-title {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  &__title {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  &__links {
    @media #{$xs} {
      margin-bottom: 20px;
    }
    & ul {
      & li {
        margin-bottom: 5px;
        color: var(--tp-text-2);
        & a {
          font-weight: 400;
          font-size: 15px;
          color: var(--tp-text-2);
          &:hover {
            color: var(--tp-theme-blue);
          }
        }
      }
    }
  }
  &__list {
    & ul {
      & li {
        color: var(--tp-text-2);
      }
    }
  }
}
.fw-social {
  & a {
    color: #b2b0c1;
    margin-right: 20px;
    & i {
      font-size: 16px;
      &:hover {
        color: var(--tp-theme-primary);
      }
    }
  }
}
.fw-border {
  padding: 30px 0;
  border-top: 1px solid var(--tp-border-primary);
}
.copyright-white {
  & span {
    color: var(--tp-theme-primary);
    & i {
      color: var(--tp-text-2);
    }
  }
}
.footer-2-col-2 {
  margin-left: 75px;
  @media #{$xl} {
    margin-left: 10px;
  }
  @media #{$lg,$md,$xs} {
    margin-left: 0px;
  }
}
.footer-area-black {
  & .tp-footer-widget__title {
    color: #fff;
  }
}
.footernewsletter {
  &__title {
    font-weight: 400;
    font-size: 24px;
    color: var(--tp-common-white);
  }
  & p {
    color: var(--tp-text-1);
    font-size: 15px;
  }
  &__form {
    & form {
      & input {
        width: 100%;
        height: 60px;
        background-color: var(--tp-theme-primary);
        border: 1px solid #312a70;
        border-radius: 5px;
        padding: 0 100px 0 20px;
        color: var(--tp-common-white);
        &::placeholder {
          color: var(--tp-text-2);
          font-size: 15px;
        }
      }
    }
  }
  &__fw-news-btn {
    background-color: var(--tp-theme-secondary);
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    border-radius: 5px;
  }
}
.footercontact {
  display: flex;
  align-items: center;
  &:hover {
    & .footercontact__icon {
      box-shadow: inset 0 0 0 50px var(--tp-icon-blue);
      color: var(--tp-common-white);
    }
    & .fw-pink-icon {
      box-shadow: inset 0 0 0 50px var(--tp-icon-pink);
      color: var(--tp-common-white);
    }
    & .fw-green-icon {
      box-shadow: inset 0 0 0 50px var(--tp-icon-green);
      color: var(--tp-common-white);
    }
  }
  &__icon {
    flex: 0 0 auto;
    margin-right: 20px;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 90px;
    border-radius: 50%;
    background-color: rgb(14, 99, 255, 0.1);
    color: var(--tp-icon-blue);
    font-size: 35px;
    @include transition(0.5s);
    @media #{$xl,$lg} {
      height: 60px;
      width: 60px;
      line-height: 70px;
      font-size: 25px;
    }
    &.fw-pink-icon {
      background-color: rgb(247, 42, 117, 0.1);
      color: var(--tp-theme-pink);
    }
    &.fw-green-icon {
      background-color: rgb(16, 208, 161, 0.1);
      color: var(--tp-theme-secondary);
    }
  }
  &__title {
    color: var(--tp-common-white);
    font-weight: 500;
    font-size: 16px;
    & a {
      &:hover {
        color: var(--tp-theme-blue);
      }
    }
  }
  &__content {
    & span {
      color: var(--tp-common-white);
      display: block;
      @media #{$xl} {
        font-size: 13px;
      }
      & a {
        & br {
          @media #{$bxxxl,$xxl,$xl,$lg,$xs,$md} {
            display: none;
          }
        }
      }
    }
  }
}
.fw-pink-content {
  & a {
    color: var(--tp-common-white);
    display: block;
    &:hover {
      color: var(--tp-theme-pink);
    }
  }
}
.cpy-white-content {
  & span {
    color: var(--tp-common-white);
    & i {
      color: var(--tp-text-2);
    }
  }
}
.cpy-white-content-info {
  & a {
    color: var(--tp-text-1);
  }
}
.fw-white-border {
  border-top: 1px solid var(--tp-common-white);
}
.tp-footer-white-content {
  & .tp-footer-widget__content {
    & i {
      color: var(--tp-text-1);
    }
    & h4 {
      color: var(--tp-common-white);
      @media #{$xs} {
        font-size: 28px;
      }
    }
    & a {
      color: var(--tp-common-white);
      &:hover {
        color: var(--tp-theme-secondary);
      }
    }
  }
  & .tp-footer-widget__sub-sec {
    & span {
      color: var(--tp-common-white);
    }
    & p {
      color: var(--tp-text-1);
    }
  }
  & .tp-footer-widget__title {
    color: var(--tp-common-white);
  }
  & .tp-footer-widget__links {
    & li {
      color: var(--tp-text-1);
    }
    & a {
      color: var(--tp-text-1);
      &:hover {
        color: var(--tp-theme-secondary);
      }
    }
  }
  & .tp-footer-widget__list {
    & li {
      color: var(--tp-text-1);
    }
  }
  & .tp-footer-widget__social {
    & a {
      & i {
        color: #b2b0c1;
        &:hover {
          color: var(--tp-common-white);
        }
      }
    }
  }
  & .footer-widget__copyright {
    & span {
      color: var(--tp-common-white);
      & i {
        color: var(--tp-text-1);
      }
    }
  }
  & .fw-border {
    border-top: 1px solid #332e66;
  }
  & .footer-widget__copyright-info {
    & a {
      color: var(--tp-text-1);
    }
  }
}
